import React, { useState } from "react";
import MediaQuery from "components/MediaQuery";
import { useHistory, Link, NavLink } from "react-router-dom";
import { useGlobal } from "reactn";
import { RampUnit, TagBasedAdUnits } from "@outplayed/ramp";
import { useLoginState, useUserPremiumState } from "lib/hooks/userHooks";
import AdBoxWrapper2 from "components/Ads/AdBoxWrapper2";
import AdComponent from "components/Ads/AdComponent";
import SearchBar from "components/SearchBar";
import NewRegionSelector from "components/Masthead/NewRegionSelector";
import AppDownloadButton from "components/Buttons/AppDownloadButton";
import { ReactComponent as LeagueLogo } from "svg/League_TextLogo.svg";
import { ReactComponent as ValorantLogo } from "svg/valorant-logo-filled.svg";
import { ReactComponent as UGGLogo } from "svg/logos/ugg-logo-blue.svg";
import { ReactComponent as LeagueSmallLogo } from "svg/League_GameSwitcher_Icon.svg";
import { ReactComponent as RiotLogo } from "svg/riot-logo.svg";
import { ReactComponent as DownloadIcon } from "svg/download-cloud-fill.svg";
import UAParser from "ua-parser-js";

const parser = new UAParser();
const os = parser.getOS();
const isMac = os?.name?.match(/mac/gi);

const DesktopAppLandingPage = () => {
  const history = useHistory();
  const isLoggedIn = useLoginState();
  const isPremium = useUserPremiumState();

  const [clientRegion] = useGlobal("clientRegion");
  const [region, setRegionValue] = useState(clientRegion);
  const [showRegions, setShowRegions] = useState(false);

  const handleTest = (value) => {
    setRegionValue(value);
  };

  const handleShowRegions = () => {
    setShowRegions(!showRegions);
  };

  const tags = {
    new: {
      label: "NEW",
      backgroundColor: "#3273fa",
      fontColor: "#fff",
    },
    beta: {
      label: "BETA",
      backgroundColor: "#24a87b",
      fontColor: "#fff",
    },
    soon: {
      label: "SOON™",
      backgroundColor: "#25254b",
      fontColor: "#cddcfe",
    },
    hot: {
      label: "HOT!",
      backgroundColor: "#dd2735",
      fontColor: "#fff",
    },
  };

  const gamesData = [
    {
      img: "game-library_lol.jpg",
      title: "League of Legends",
      url: "#",
    },
    {
      img: "game-library_val.jpg",
      title: "Valorant",
      tag: tags.hot,
      url: "https://u.gg/val?utm_source=ugghome&utm_campaign=gs2-lol2val",
    },
    {
      img: "game-library_tft.jpg",
      title: "Teamfight Tactics",
      url: "https://u.gg/tft/comps?utm_source=ugghome&utm_campaign=gs2-lol2tft",
    },
    {
      img: "game-library_wow.webp",
      title: "World of Warcraft",
      url: "https://u.gg/wow?utm_source=ugghome&utm_campaign=gs2-lol2wow",
    },
    {
      img: "game-library_hd2.jpg",
      title: "Helldivers 2",
      tag: tags.new,
      url: "https://u.gg/hd2?utm_source=ugghome&utm_campaign=gs2-lol2hd2",
    },
    {
      img: "game-library_2xko.png",
      title: "2XKO",
      tag: tags.soon,
    },
    {
      img: "game-library_more-games.svg",
      title: "Stay Tuned...",
      tag: tags.soon,
    },
  ];

  return (
    <div className="desktop-app-landing-page">
      <div className="landing-background">
        <div className="overlay" />
        <div className="background-image" />
      </div>
      <div className="landing-page-layout-container">
        <div className="desktop-app-landing-page_header">
          <img
            className="ugg-logo"
            src="https://static.bigbrain.gg/assets/lol/performance_analysis/images/UGG_Logo_Blue_3.svg"
            alt="U.GG Logo"
          />
          <div className="header-right-side">
            <p>Auto-import runes & more</p>
            <div className="download-button-container">
              <AppDownloadButton url="/app?utm_source=ugghome&utm_campaign=homelogo" label="Download App" icon="cloud" />
              <RiotLogo className="riot-logo" />
              <p>Riot compliant</p>
            </div>
          </div>
        </div>
        <div className="large-search search-container">
          <SearchBar
            isLanding={true}
            searchDefault={""}
            placeholder={"Search Yourself or a Champion"}
            history={history}
            autoFocus={true}
            searchBarId="landing"
            region={region}
            handleShowRegions={handleShowRegions}
            showRegions={showRegions}
          />
          {showRegions && <NewRegionSelector handleTest={handleTest} />}
        </div>
        {(!isLoggedIn || !isPremium.isPremium) && (
          <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE" renderNullOnFail isClient>
            {/* <AdBoxWrapper2 styles={{ marginTop: 40, marginBottom: -140 }}> */}
              <RampUnit
                type={TagBasedAdUnits.LeaderboardATF}
                id="home-leaderboard-atf"
                pathname={location.pathname}
                styles={{ marginTop: 40, marginBottom: -140, width: 728, height: 90, overflow: "hidden" }}
              />
            {/* </AdBoxWrapper2> */}
          </MediaQuery>
        )}
        <div className="featured-product-container">
          <div className="featured-product-left-side">
            <p>Featured U.GG Product</p>
            <div className="logo-and-title">
              <div className="game-logo-container">
                <ValorantLogo className="game-logo" />
              </div>
              Valorant App
            </div>
            <p className="subtext">Peek Maps, Scouting & More</p>
            <div className="buttons-container">
              <a href="https://u.gg/val/get-windows-app" download>
                <div className="download-button promo-button">
                  <DownloadIcon className="download-icon" /> Download
                </div>
              </a>
              <a href="https://u.gg/val/app?utm_source=ugghome&utm_campaign=homefeatured" target="_blank">
                <div className="see-features-button promo-button">See Features</div>
              </a>
            </div>
            <div className="riot-compliance-container">
              <RiotLogo className="riot-logo" /> Fully compliant with Riot Games
            </div>
          </div>
          <div className="featured-product-right-side">
            <video autoPlay loop muted>
              <source type="video/webm" src="https://static.bigbrain.gg/val/video/valorant-promo.webm" />
            </video>
          </div>
        </div>
        <div className="game-library-container">
          <div>Games</div>
          <div className="all-games">
            {gamesData.map((game, index) => {
              const GameContainer = () => (
                <div className="game-container">
                  {game.tag && (
                    <div className="game-tag" style={{ backgroundColor: game.tag.backgroundColor, color: game.tag.fontColor }}>
                      {game.tag.label}
                    </div>
                  )}
                  <div
                    className="img-container"
                    style={{ backgroundImage: `url(https://static.bigbrain.gg/assets/lol/landing/game-library/${game.img})` }}
                  >
                    <div className="hover-gloss" />
                  </div>
                  <div className="game-title">{game.title}</div>
                </div>
              );

              return game.url ? (
                <a key={index} href={game.url}>
                  <GameContainer />
                </a>
              ) : (
                <GameContainer key={index} />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopAppLandingPage;
