import React, { useState, useEffect, useRef } from "react";
import { useGlobal } from "reactn";
import classnames from "classnames";

const RegionIcon = ({ value, label, shorthandLabel, regionToApply, isMobile }) => {
  let selected = regionToApply === value ? value : "non-selected";
  let classes = !isMobile ? `region-icon ${selected}` : `region-icon ${value} mobile`;
  return (
    <div className={classnames(classes)} style={{ zIndex: 0 }}>
      {shorthandLabel}
    </div>
  );
};

const NewRegionSelector = (props) => {
  const { onApply, currentRegion, handleTest, isMobile, handleShowRegions, isLivePage } = props;

  const [inputValue, setInputValue] = useState("");
  const [clientRegion, setClientRegion] = useGlobal("clientRegion");
  const [regionToApply, setRegionToApply] = useState(currentRegion || clientRegion);
  const [init, setInit] = useState(false);
  const regionRef = useRef();

  const availableRegions = [
    { value: "na1", label: "North America", shorthandLabel: "NA" },
    { value: "euw1", label: "Europe West", shorthandLabel: "EUW" },
    { value: "eun1", label: "Europe Nordic & East", shorthandLabel: "EUN" },
    { value: "kr", label: "Korea", shorthandLabel: "KR" },
    { value: "br1", label: "Brazil", shorthandLabel: "BR" },
    { value: "jp1", label: "Japan", shorthandLabel: "JP" },
    { value: "ru", label: "Russia", shorthandLabel: "RU" },
    { value: "oc1", label: "Oceania", shorthandLabel: "OCE" },
    { value: "tr1", label: "Turkey", shorthandLabel: "TR" },
    { value: "la1", label: "Latin America North", shorthandLabel: "LAN" },
    { value: "la2", label: "Latin America South", shorthandLabel: "LAS" },
    { value: "ph2", label: "Philippines", shorthandLabel: "PH" },
    { value: "sg2", label: "Singapore", shorthandLabel: "SG" },
    { value: "th2", label: "Thailand", shorthandLabel: "TH" },
    { value: "tw2", label: "Taiwan", shorthandLabel: "TW" },
    { value: "vn2", label: "Vietnam", shorthandLabel: "VN" },
  ];

  useEffect(() => {
    if (window) {
      const handleOutsideClick = (e) => {
        const path = e.path || (e.composedPath && e.composedPath());
        if (
          regionRef.current &&
          e.target !== null &&
          !regionRef.current.contains(e.target) &&
          !path.includes(regionRef.current)
        ) {
          handleShowRegions();
        }
      };

      window.addEventListener("click", handleOutsideClick);
      return () => window.removeEventListener("click", handleOutsideClick);
    }
  }, []);

  useEffect(() => {
    setInit(true);
  }, []);

  if (!init) {
    return null;
  }

  const setRegion = (region) => {
    if (onApply && region && region !== currentRegion) {
      onApply(region);
    }
    // Else, set universal region
    else if (region && region !== clientRegion) {
      localStorage.setItem("summonerRegion", region);
      setClientRegion(region);
    }
    setInputValue("");
    setRegionToApply(region);
    handleTest(region);
    handleShowRegions();
  };

  return isMobile ? (
    <div className="all-region-dropdown" ref={regionRef}>
      <div className="region-header">
        <span>Region</span>
      </div>
      {availableRegions.map((region, index) => (
        <div className="indv-region-selector" key={region.value} onClick={() => setRegion(region.value)}>
          <RegionIcon
            value={region.value}
            label={region.label}
            shorthandLabel={region.shorthandLabel}
            regionToApply={regionToApply}
            isMobile={isMobile}
          />
          <span>{region.label}</span>
        </div>
      ))}
    </div>
  ) : (
    <div className="all-region-selector">
      {availableRegions.map((region, index) => (
        <div key={region.value} onClick={() => setRegion(region.value)}>
          <RegionIcon
            value={region.value}
            label={region.label}
            shorthandLabel={region.shorthandLabel}
            regionToApply={regionToApply}
          />
        </div>
      ))}
    </div>
  );
};

export default NewRegionSelector;
