import React, { Component } from "react";
import { useGlobal } from "reactn";
import PropTypes from "prop-types";

const AdBoxWrapper2 = (props) => {
  const [adblock] = useGlobal("adblock");
  const [isDetectingAdblock] = useGlobal("isDetectingAdblock");
  const { className, children, styles, minAdWidth, minAdHeight } = props;

  if (adblock || isDetectingAdblock) {
    return null;
  }

  const adContainerStyle = {
    position: "relative",
    minWidth: minAdWidth,
    width: "auto",
    minHeight: minAdHeight,
    height: "auto",
  };

  return (
    <div className={`ad-box-wrapper ${className}`} style={styles}>
      <span className="ad-box-label-2">Advertisement</span>
      <div style={adContainerStyle}>{children}</div>
    </div>
  );
};

export default AdBoxWrapper2;

AdBoxWrapper2.defaultProps = {
  styles: {},
  minAdWidth: 0,
  minAdHeight: 0,
};
