import React from "react";
import { ReactComponent as HD2Skull } from "svg/hd2_skull.svg";
import { ReactComponent as UGGXHD2Logo } from "svg/uggxhd2.svg";
import { ReactComponent as Arrow } from "svg/arrow-right.svg";

const HD2Promo = () => {
  return (
    <a href="https://u.gg/hd2?utm_medium=ugghome&utm_campaign=hd2banner1" className="hd2-banner-container">
      <div className="new-tag">NEW!</div>
      <UGGXHD2Logo className="uggxhd2-logo" />
      <div className="text-container">
        <div className="title with-span">
          <span className="hd2-title">HELLDIVERS 2:</span> NEW MAJOR ORDER
        </div>
        <div className="title without-span">HELLDIVERS 2: NEW MAJOR ORDER</div>
        <div className="title mobile">HELLDIVERS 2</div>
        <div className="subtext">Helldivers 2 has arrived on U.GG</div>
        <div className="subtext plus">
          Helldivers 2 has arrived on U.GG. Check it out <Arrow className="arrow-icon" />
        </div>
      </div>
      <div className="hd2-button">
        <HD2Skull className="skull-icon" /> Check out Helldivers 2
      </div>
    </a>
  );
};

export default HD2Promo;
